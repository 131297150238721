<template>
  <div
    class="x-login-page"
    :style="setBackground"
  >
    <zoom-center-transition
      :duration="pageTransitionDuration"
      mode="out-in"
    >
      <div class="login-content">
        <material-card
          class="v-card-profile"
          style="text-align:center"
          :width="270"
        >
          <img
            class="my-logo mt-2"
            :src="image"
          >
          <v-card-text class="text-xs-center">
            <h4 
              v-if="!isProductEnv" 
              class="card-title font-weight-light mt-1 mb-3 success--text"
            >
              [ Test ]
            </h4>
            <h4 class="card-title font-weight-light mt-1 mb-3"><b>Welcome</b></h4>
            <p class="card-description font-weight-light mb-3">Please login with your <br>Nielsen Email Account</p>
            <v-btn
              color="primary"
              rounded
              class="font-weight-light ma-3"
              @click="loginMsal"
              :flat="loading"
              :loading="loading"
              dark
            >
              Login
            </v-btn>
          </v-card-text>
        </material-card>

        <copyright-component text-color="white" />
      </div>
    </zoom-center-transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ZoomCenterTransition } from "vue2-transitions"
import { isProductEnv, redirUrl, subPath } from '@/config'
import CopyrightComponent from './copyright/copyright.vue'

export default {
  name: 'Login',
  components: {
    ZoomCenterTransition,
    CopyrightComponent,
  },
  data() {
    return {
      loading: false,
      pageTransitionDuration: 300,
      password: null,
      image: require('@/static/img/icons/login.png'),

      isProductEnv: isProductEnv,
    }
  },
  computed: {
    /**
     * vuex - state / getters
     */
    ...mapGetters('app', [
      'isMobile'
    ]),

    setBackground() {
      return {
        'background-image': `url(${require('@/static/img/app/bg/login.jpg')})`
      };
    }
  },
  methods: {
    /**
     * login
     * login microsoft account - redirect
     */
    loginMsal () {
      this.$eventBus.$emit('msal-login')
    },
  },
}
</script>

<style lang="sass">
.x-login-page
  height: 100%
  min-height: 100vh
  background-position: top
  background-size: cover
  margin: 0
  padding: 0 0 0 100px
  border: 0
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  justify-content: center

  &:before
    position: absolute
    width: 100%
    height: 100%
    display: block
    left: 0
    top: 0
    content: ""
    background-color: rgba(0, 0, 0, .5)
  

  .v-card-profile
    margin-right: 100px
  

  .login-content
    .my-logo
      width: 110px
      -webkit-box-shadow: none !important
      box-shadow: none !important
    

    .copyright
      position: fixed
      left: 0
      bottom: 0
      text-align: center !important
      width: 100%
      color: rgba(255,255,255,.9)
      font-size: 12px
    
  

</style>
